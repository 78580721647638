.cardBody {
  background: #f7f9fc;
  border-radius: 8px;
  max-width: 300px;
  width: 100%;
  margin-right: 0;
  border: none;
  .profilePic {
    border-radius: 8px;
  }
  .pointer {
    cursor: pointer;
  }
  .pointer:hover {
    cursor: pointer;
  }
  p {
    margin: 0;
    font-family: "Montserrat";
  }
  .name {
    font-weight: 600;
    font-size: 16px;
    color: #2e3a59;
    margin: 0;
  }
  .designation {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: #8f9bb3;
  }
  .locationIcon {
    height: 18px;
    width: 18px;
  }
  .adress {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #2e3a59;
    text-transform: capitalize;
    padding: 0;
    text-align: left;
    margin-left: 5px;
  }
  .country {
    text-transform: capitalize;
    text-align: left;
    color: #2e3a59;
  }
  .overideBtn {
    width: 100%;
    .containedButton {
      width: 100%;
      max-width: 320px;
      color: #ffff;
      background: #278fff;
      border-radius: 15px;
    }
  }
}
